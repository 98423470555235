<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal_form_solicitud_conductores"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Solicitud Conductores
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group">
                    <div class="form-group row">
                      <label
                        for="fecha_creacion"
                        class="col-md-5"
                        style="font-size: 20px"
                        >Fecha Creación</label
                      >

                      <input
                        type="date"
                        v-model="filters.fecha_creacion"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Tipo Documento</label
                      >
                      <v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Tipo Documento"
                        v-model="tipo_documento"
                        @input="selectTipoDocumento()"
                        label="descripcion"
                        :options="$parent.listsForms.tipo_documentos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Numero Documento</label
                      >
                      <input
                        type="text"
                        v-model="filters.documento"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Email</label
                      ><input
                        type="text"
                        v-model="filters.email"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Empresa</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Empresa"
                        label="descripcion"
                        @input="selectEmpresa()"
                        v-model="empresa"
                        :options="$parent.listsForms.empresas"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Activo</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Activo"
                        label="descripcion"
                        @input="selectActivo()"
                        v-model="activo"
                        :options="$parent.listsForms.activos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Tipo Constrato</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Tipo Contrato"
                        v-model="tipo_contrato"
                        @input="selectTipoContrato()"
                        label="descripcion"
                        :options="$parent.listsForms.tipos_contratos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Categoria Licencia</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Categoria Licencia"
                        label="descripcion"
                        v-model="categoria_licencia"
                        @input="selectCategoriaLicencia()"
                        :options="listsForms.categorias_licencias"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Restricción Licencia</label
                      >
                      <select
                        class="form-control form-control-sm col-md-7 p-0"
                        v-model="filters.restriccion"
                        id="estado"
                      >
                        <option value="0">No</option>
                        <option value="1">Si</option>
                      </select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Experiencia Conduciendo</label
                      ><input
                        type="number"
                        v-model="filters.experiencia"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Clase Vehículo</label
                      ><v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Clase Vehículo"
                        @input="selectClaseVh()"
                        label="descripcion"
                        :options="$parent.listsForms.clases_vehiculos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Comparendos</label
                      ><select
                        class="form-control form-control-sm col-md-7 p-0"
                        v-model="filters.Comparendos"
                        id="Comparendos"
                      >
                        <option value="0">No</option>
                        <option value="1">Si</option>
                      </select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Estado</label
                      >
                      <select
                        class="form-control form-control-sm col-md-7 p-0"
                        v-model="filters.estado"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estadoOpc in $parent.listsForms.estados"
                          :key="estadoOpc.numeracion"
                          :value="estadoOpc.numeracion"
                        >
                          {{ estadoOpc.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group row">
                      <label
                        for="fecha_inicial"
                        class="col-md-5"
                        style="font-size: 20px"
                        >Fecha Runt</label
                      >
                      <input
                        type="date"
                        v-model="filters.fecha_runt"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                    <div class="form-group row">
                      <label
                        for="fecha_final"
                        class="col-md-5"
                        style="font-size: 20px"
                        >Fecha Nacimiento</label
                      >

                      <input
                        type="date"
                        v-model="filters.fecha_nacimiento"
                        class="form-control form-control-sm col-md-7"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="checks.tipo_documento"
                              id="tipo_documento"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_documento"
                              >Tipo Documento</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="checks.numero_documento"
                              id="numero_documento"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="numero_documento"
                              >Número Documento</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombres"
                              v-model="checks.nombres"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombres"
                              >Nombres</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="email"
                              v-model="checks.email"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="email"
                              >Email</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="empresa"
                              v-model="checks.empresa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresa"
                              >Empresa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="activo"
                              v-model="checks.activo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="activo"
                              >Activo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_contrato"
                              v-model="checks.tipo_contrato"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_contrato"
                              >Tipo Contrato</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_nacimiento"
                              v-model="checks.fecha_nacimiento"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_nacimiento"
                              >Fecha Nacimiento</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_runt"
                              v-model="checks.fecha_runt"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="fecha_runt"
                              >Fecha Runt</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="categoria_licencia"
                              v-model="checks.categoria_licencia"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="categoria_licencia"
                              >Categoria Licencia</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="restriccion_licencia"
                              v-model="checks.restriccion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="restriccion_licencia"
                              >Restricción Licencia</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="experiencia"
                              v-model="checks.experiencia"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="experiencia"
                              >Experiencia Conduciendo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="checks.clase_vh"
                              id="clase_vehiculo"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="clase_vehiculo"
                              >Clase Vehículo</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="comparendos"
                              v-model="checks.comparendos"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="comparendos"
                              >Comparendos</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="created"
                              v-model="checks.created"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="created"
                              >Fecha Creación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="check_estado"
                              v-model="checks.estado"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="check_estado"
                              >Estado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="check_observaciones"
                              v-model="checks.observaciones"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="check_observaciones"
                              >Observaciones</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer justify-content-between">
              <div>
                <button
                  type="button"
                  @click="getIndex()"
                  class="btn btn-sm bg-navy"
                >
                  Generar Listado
                  <i class="fas fa-file-download"> </i>
                </button>
              </div>
            </div>
          </div>

          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>
  <script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "SolicitudesConductoresExport",
  components: { vSelect },
  data() {
    return {
      cargando: false,
      tipo_documento: null,
      empresa: null,
      activo: null,
      tipo_contrato: null,
      categoria_licencia: null,

      checks: {
        tipo_documento: true,
        numero_documento: true,
        nombres: true,
        email: true,
        empresa: true,
        activo: true,
        tipo_contrato: true,
        fecha_nacimiento: true,
        fecha_runt: true,
        categoria_licencia: true,
        restriccion: true,
        experiencia: true,
        clase_vh: true,
        comparendos: true,
        created: true,
        estado: true,
        observaciones: true,
      },
      filters: {
        documento: null,
        email: null,
        restriccion: null,
        experiencia: null,
        Comparendos: null,
        estado: null,
        fecha_runt: null,
        fecha_nacimiento: null,
        fecha_creacion: null,
        categoria_licencia_id: null,
        tipo_documento_id: null,
        empresa_id: null,
        activo_id: null,
        tipo_contrato_id: null,
      },
      listsForms: {
        modelos: [],
        tipos_vehiculos: [],
        areas: [],
        categorias_licencias: [],
      },
    };
  },
  methods: {
    getIndex() {
      this.cargando = true;
      let datos = {
        filters: this.filters,
        checks: this.checks,
      };
      axios
        .post("api/hse/HseSolicitudesConductoresExport", datos)
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
    getTipoVh() {
      axios
        .get("api/hse/HseSolicitudesVehiculos/listaTipoVh")
        .then((response) => {
          this.listsForms.tipos_vehiculos = response.data;
        });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listsForms.areas = response.data;
      });
    },

    getCategoriasLicencias() {
      axios.get("/api/lista/17").then((response) => {
        this.listsForms.categorias_licencias = response.data;
      });
    },

    //Seleccionar solo ids
    selectEmpresa() {
      this.filters.empresa_id = null;
      if (this.empresa) {
        this.filters.empresa_id = this.empresa.numeracion;
      }
    },

    selectActivo() {
      this.filters.activo_id = null;
      if (this.activo) {
        this.filters.activo_id = this.activo.numeracion;
      }
    },

    selectTipoContrato() {
      this.filters.tipo_contrato_id = null;
      if (this.tipo_contrato) {
        this.filters.tipo_contrato_id = this.tipo_contrato.numeracion;
      }
    },

    selectTipoDocumento() {
      this.filters.tipo_documento_id = null;
      if (this.tipo_documento) {
        this.filters.tipo_documento_id = this.tipo_documento.numeracion;
      }
    },
    selectClaseVh() {
      this.filters.clase_vh_id = null;
      if (this.clase_vh) {
        this.filters.clase_vh_id = this.clase_vh.numeracion;
      }
    },

    selectCategoriaLicencia() {
      this.filters.categoria_licencia_id = null;
      if (this.categoria_licencia) {
        this.filters.categoria_licencia_id = this.categoria_licencia.numeracion;
      }
    },
  },

  mounted() {
    this.getTipoVh();
    this.getAreas();
    this.getCategoriasLicencias();
  },
};
</script>
    
    